import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, Button, Modal, Tooltip, Tag, Divider } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import ProductTypeForm from './ProductTypeForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';

import moment from 'moment';
// actions
import { getAllProductType, getProductType, removeProductType } from '../../redux/actions/ProductTypeActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/product-type',
        breadcrumbName: 'Danh sách loại sản phẩm',
    },
]

class ProductTypeList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentProductType: null
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductType(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProductType(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/product-type',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/product-type',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, product_type = null) {
        this.setState({ isOpenForm: isOpenForm, currentProductType: product_type });
    }

    onEdit(id) {
        this.props.getProductType(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeProductType(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeProductType(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    render() {
        var { product_type, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentProductType } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '80px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('product_type', 'update') ? (
                                <>
                                    <EditOutlined style={{ color: '#1BAE70', cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('product_type', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Mô tả',
                dataIndex: 'description',
                key: 'description',
                width: '300px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip placement="topLeft" color="#3cba81">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Đơn vị fulfill',
                dataIndex: 'unit_fulfill',
                key: 'unit_fulfill',
                sorter: true,
                width: '140px',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                )
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách loại sản phẩm" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('product_type', 'create')}
                        isShowDeleteButton={checkPermission('product_type', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={product_type}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProductTypeForm
                    visible={isOpenForm}
                    product_type={currentProductType}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_type: state.ProductType.product_type,
        pagination: state.ProductType.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProductType: (filter) => dispatch(getAllProductType(filter)),
        getProductType: (id) => dispatch(getProductType(id)),
        removeProductType: (ids) => dispatch(removeProductType(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeList);
