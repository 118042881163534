import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './home';
import ProductsList from './products/ProductsList';
import ProcessList from './products-import/ProcessList';
import ProductsDescriptionList from './products-description/ProductsDescriptionList';
import ProductType from './product-type/ProductTypeList';

import { Typography } from 'antd';
const { Title } = Typography;

const ProtectedRoute = ({ authUser, permission, component: Component, ...rest }) => (
    <Route
        {...rest}
        // render={(props) => (
        //     authUser ? <Component {...props} /> : <Redirect to='/login' />
        // )} 
        render={(props) => {
            if (authUser) {
                let availablePermissions = Object.keys(authUser.permissions);
                let isAccess = false;
                if (authUser.role_code == 'ADMIN') isAccess = true;
                else {
                    if (!permission || availablePermissions.indexOf(permission) >= 0) isAccess = true;
                }

                if (isAccess) return <Component {...props} />;
                // else return <Redirect to='/error/403' />;
                else return (
                    <div className="text-center mt-4">
                        <Title>Permission access is denied</Title>
                    </div>
                )
            } else {
                return <Redirect to='/login' />;
            }
        }}
    />
)

const routes = [
    {
        path: '/',
        component: Home,
        permission: null
    },
    {
        path: '/products',
        component: ProductsList,
        permission: 'products'
    },
    {
        path: '/products-import',
        component: ProcessList,
        permission: 'products_import'
    },
    {
        path: '/products-description',
        component: ProductsDescriptionList,
        permission: 'products_description'
    },
    {
        path: '/product-type',
        component: ProductType,
        permission: 'product_type'
    }
];

//console.log(routes);  

class Routes extends Component {

    render() {
        var { authUser } = this.props;

        return (
            <Switch>
                {
                    routes.map((route, index) => {
                        return (
                            <ProtectedRoute exact authUser={authUser} permission={route.permission} path={route.path} component={route.component} key={index} />
                        )
                    })
                }
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state);
    return {
        authUser: state.auth.authUser
    }
}

export default connect(mapStateToProps, null)(Routes);

