import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, DatePicker, Divider, Button, Tag } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import BaseSelect from '../../components/Elements/BaseSelect';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar
} from 'recharts';
import { statisProduct } from '../../redux/actions/StatisticalAction';
import { getStoreByField } from '../../redux/actions/StoreActions';

function handleData(month, year, metrics) {
    var endOfMonth = parseInt(moment([year, month - 1]).endOf('month').format('DD'));
    var days = _.times(endOfMonth + 1);
    days.splice(0, 1);

    var data = days.map(day => {
        let items = metrics.map(itemKey => {
            if (Array.isArray(itemKey.data)) {
                return {
                    name: itemKey.name,
                    metrics: itemKey.data.find(item => item.post_day == day) == undefined ? [] : itemKey.data.find(item => item.post_day == day)
                }
            } else {
                return {
                    name: itemKey.name,
                    metrics: []
                }
            }
        })
        const extractedKeys = {}
        items.forEach(o => extractedKeys[o?.name] = o?.metrics.count == undefined ? 0 : parseInt(o?.metrics.count));

        return {
            day: day,
            ...extractedKeys
        }
    });

    return _.sortBy(data, 'day');
}

class Home extends Component {
    state = {
        isLoading: false,
        month: moment().format('M'),
        year: moment().format('Y'),
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        let month = this.state.month;
        let year = this.state.year;
        this.props.getStoreByField({ product_statistics: 'yes', platform: ['pod', 'dropship', 'shield'] });
        await this.props.statisProduct({ month: month, year: year }).then(res => {
            this.setState({ isLoading: false })
        });
    }

    changeMonth(date) {
        this.setState({
            month: date.format('M'),
            year: date.format('Y')
        }, () => {
            let month = this.state.month;
            let year = this.state.year;
            this.props.statisProduct({ month: month, year: year }).then(res => {
                this.setState({ isLoading: false })
            });
        })
    }

    reloadData() {
        this.setState({
            isLoading: true
        });
        let month = this.state.month;
        let year = this.state.year;
        this.props.statisProduct({ month: month, year: year }).then(res => {
            this.setState({ isLoading: false })
        });
    }

    async onChangeStaff(staff_id) {
        this.setState({ isLoading: true })
        let month = this.state.month;
        let year = this.state.year;
        this.props.getStoreByField({ product_statistics: 'yes', staff_id: staff_id, platform: ['pod', 'dropship', 'shield'] });
        await this.props.statisProduct({ month: month, year: year, staff_id: staff_id }).then(res => {
            this.setState({ isLoading: false })
        });
    }

    render() {
        const { isLoading, month, year } = this.state;
        const { statis_product, store_by_field_list, authUser, users } = this.props;

        var productData = handleData(month, year, statis_product);

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload && payload.length) {
                //console.log("payload: ", payload)
                return (
                    <div className="custom-tooltip-chart">
                        <p className="label">{label}</p>
                        <p className="desc">
                            {
                                payload.map((site) => {
                                    return (
                                        //<Tag color={site.color}>{site.name} : {site.value}</Tag>
                                        <Tag>{site.name} : {site.value}</Tag>
                                    )
                                })
                            }
                        </p>
                    </div>
                );
            }

            return null;
        };

        return (
            <div className='mt-3'>
                <div style={{ marginBottom: '10px' }}>
                    <div>
                        <DatePicker.MonthPicker allowClear={false} style={{ width: '200px' }} format="MM/YYYY" placeholder="Chọn tháng" onChange={(date) => this.changeMonth(date)} />
                        <Divider type="vertical" />
                        <Button loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </div>
                </div>
                <Card
                    className="mb-4"
                    title={<>{`Sản phẩm tháng ${month + '/' + year}`} <i style={{ fontWeight: 'normal' }}>(Theo giờ UTC+0)</i></>}
                    size='small'
                    loading={isLoading}
                    extra={<>
                        {
                            authUser.role_code == "ADMIN" ? (
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    defaultText="Nhận viên quản lý"
                                    placeholder="Nhận viên quản lý"
                                    style={{ width: '200px' }}
                                    onChange={(value) => this.onChangeStaff(value)}
                                />
                            ) : null
                        }
                    </>}
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 16 }}>
                        <Col md={24} sm={24} className="mb-2">
                            <ResponsiveContainer width="100%" height={450}>
                                <LineChart
                                    width={700}
                                    height={450}
                                    data={productData}
                                    margin={{
                                        top: 15, right: 5, left: 0, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="2 2" />
                                    <XAxis dataKey="day" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    {store_by_field_list.map((s) => (
                                        <Line type="monotone" dataKey={s.name} strokeWidth={1} stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Card>
            </div >
        )
    }

}

function mapStateToProps(state) {
    //console.log(state)
    return {
        statis_product: state.StatisticalReducer.statis_product,
        authUser: state.auth.authUser,
        users: state.config.users,
        store_by_field_list: state.StoreReducer.store_by_field_list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        statisProduct: (filter) => dispatch(statisProduct(filter)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);