import api from '../../utils/api';
import {
    GET_ALL_STORE_BY_ACTIVE,
    GET_ALL_STORE_BY_FILED
} from './types';
import { NotificationManager } from 'react-notifications';

export const getStoreActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/store', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_STORE_BY_ACTIVE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStoreByField = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/store', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_STORE_BY_FILED,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}