import {
   GET_CONFIG
} from './types';
import api from '../../utils/api';

export const getConfig = () => dispatch => {
    return api.get('/config/get').then(res => {
        dispatch({type: GET_CONFIG, payload: res.data.data})
    }).catch(err => {
        console.log(err);
    })
}