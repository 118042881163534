import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Drawer, Space, Modal } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

// actions
import { deletetProductByPermalink } from '../../redux/actions/ProductsAction';

const { confirm } = Modal;

class DeleteProductByLink extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {

    }

    onDelete(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    var data = {
                        store_id: this.props.store_id,
                        list_links: values.list_links
                    };
                    confirm({
                        title: 'Xác nhận',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Chắc chắn xóa các sản phẩm này?',
                        okText: 'OK',
                        cancelText: 'Huỷ',
                        onOk: async () => {
                            await this.props.deletetProductByPermalink(data);
                            this.onClose();
                        }
                    })
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;
        const { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Xóa sản phẩm theo permalink"
                width={isMobile ? '100%' : '80%'}
                onClose={() => this.props.onCancel()}
                maskClosable={false}
                extra={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="danger"
                                icon={<DeleteOutlined />}
                                onClick={(e) => this.onDelete(e)}
                            >
                                Tiến hành xóa
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ? (
                    <div>
                        <Form layout='vertical'>
                            <Form.Item label="Nhập link sản phẩm">
                                {getFieldDecorator('list_links', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng điền!",
                                        },
                                    ],
                                    initialValue: null
                                })(
                                    <Input.TextArea rows={20} placeholder="
                                Mỗi link trên 1 dòng theo mẫu sau:
                                http://example-1.com/link-1/
                                http://example-2.com/link-2/
                                http://example-3.com/link-3/" />
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deletetProductByPermalink: (data) => dispatch(deletetProductByPermalink(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DeleteProductByLink" })(DeleteProductByLink));
