import api from '../../utils/api';
import {
    UPDATE_DESCRIPTION_QUEUE,
    PRODUCTS_IMPORT_QUEUE,
    CREATE_PRODUCTS_QUEUE,
    REMOVE_PRODUCTS_QUEUE,
    GET_SPECIFIC_PRODUCTS_IMPORT_QUEUE,
    UPDATE_PRODUCTS_IMPORT_QUEUE,
    PRODUCTS_IMPORT_NOW,
    DELETE_QUEUE_IMAGE,
    SET_THUMBNAIL,
    UPDATE_SPECIFIC_RECORD,
    RESET_IMPORT_STATUS_PRODUCTS_QUEUE
} from './types';
import { NotificationManager } from 'react-notifications'
import { Store } from 'react-notifications-component'

export const getAllQueue = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/products-import', { params: filter }).then(res => {
            dispatch({
                type: PRODUCTS_IMPORT_QUEUE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createQueue = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products-import/create-queue', data).then(res => {
            dispatch({
                type: CREATE_PRODUCTS_QUEUE,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeQueue = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/products-import`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PRODUCTS_QUEUE, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const resetImportStatus = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/products-import/reset-import`, { ids: ids }).then(res => {
            dispatch({ type: RESET_IMPORT_STATUS_PRODUCTS_QUEUE, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const setThumbnail = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put('/products-import/set-thumbnail', data).then(res => {
            dispatch({
                type: SET_THUMBNAIL,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeQueueImage = (id, queue_id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/products-import/delete-queue-images`, { data: { id: id, queue_id: queue_id } }).then(res => {
            dispatch({ type: DELETE_QUEUE_IMAGE, payload: res.data.data });
            //NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateDescription = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products-import/update-description', data).then(res => {
            dispatch({
                type: UPDATE_DESCRIPTION_QUEUE,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOne = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/products-import/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCTS_IMPORT_QUEUE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const manyUpdate = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put('/products-import/many-update', data).then(res => {
            dispatch({
                type: UPDATE_PRODUCTS_IMPORT_QUEUE,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const importNow = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/products-import/import-now`, data).then(res => {
            dispatch({ type: PRODUCTS_IMPORT_NOW, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err)
            if (err.response.status == 422) {
                NotificationManager.error("Đã đủ số lượng sản phẩm trong ngày");
            }
            reject(err);
        })
    })
}

export const updateRecordProduct = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/products-import/update-record/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_SPECIFIC_RECORD,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
