import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ConfigReducer from "./ConfigReducer";
import ProductsReducer from './ProductsReducer';
import ProductsCategories from './ProductsCategoriesReducer';
import ProductsTags from './ProductsTagsReducer';
import ProductsImportReducer from './ProductsImportReducer';
import ProductTypeReducer from './ProductTypeReducer';
import StatisticalReducer from './StatisticalReducer';
import ProductsDescriptionReducer from './ProductsDescriptionReducer';
import StoreReducer from './StoreReducer';
import ChatGptReducer from './ChatGptReducer';
import ProductsDescriptionContentReducer from './ProductsDescriptionContentReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    config: ConfigReducer,
    ProductsReducer: ProductsReducer,
    ProductsCategories: ProductsCategories,
    ProductsTags: ProductsTags,
    ProductsImportReducer: ProductsImportReducer,
    ProductsDescriptionReducer: ProductsDescriptionReducer,
    StatisticalReducer: StatisticalReducer,
    StoreReducer: StoreReducer,
    ProductType: ProductTypeReducer,
    ChatGptReducer: ChatGptReducer,
    ProductsDescriptionContentReducer: ProductsDescriptionContentReducer
});

export default rootReducer;