module.exports = {
    "admin.user.list": "Xem danh sách user",
    "admin.user.create": "Thêm mới user",
    "admin.user.detail": "Xem chi tiết user",
    "admin.user.update": "Cập nhật thông tin user",
    "admin.user.remove": "Xoá user",

    // card title
    "admin.user": "User"
};
